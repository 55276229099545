var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"br-16 elevation-1",attrs:{"headers":_vm.headers,"items":_vm.selected_brand.custom_pages,"items-per-page":10,"loading":_vm.custom_page_datatable_loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex justify-end align-center br-16 pa-4"},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-tooltip","disabled":_vm.selected_brand.custom_pages.length < 5},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{staticClass:"mr-0",attrs:{"color":"yellow","rounded":"","to":{
                  name: 'admin-brands-id-new-page',
                },"disabled":_vm.selected_brand.custom_pages.length > 4}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"black"}},[_vm._v("mdi-plus")]),_vm._v("Add new page")],1)],1)]}}])},[_c('span',[_vm._v("The number of custom pages is limited to a maximum of 5")])])],1)]},proxy:true},{key:"header.order",fn:function(){return [_vm._v(" Order "),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1 mb-1",attrs:{"color":"primary","small":""}},on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v(" Your website's menu bar will follow the order of the pages displayed here in this table ")])])]},proxy:true},{key:"item.order",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"\n          change-order-arrow-wrapper\n          d-flex\n          flex-column\n          justify-center\n          align-center\n          pb-2\n        "},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [(item.order > 0)?_c('v-icon',{staticClass:"no-icon-ripple",attrs:{"color":hover ? 'primary' : 'dark-grey',"large":""},on:{"click":function($event){return _vm.change_custom_page_order({
                page_id: item.id,
                direction: 'down',
              })}}},[_vm._v("mdi-menu-up")]):_vm._e()]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [(item.order < _vm.selected_brand.custom_pages.length - 1)?_c('v-icon',{staticClass:"no-icon-ripple",attrs:{"color":hover ? 'primary' : 'dark-grey',"large":""},on:{"click":function($event){return _vm.change_custom_page_order({ page_id: item.id, direction: 'up' })}}},[_vm._v("mdi-menu-down")]):_vm._e()]}}],null,true)})],1)]}},{key:"item.is_active",fn:function(ref){
              var item = ref.item;
return [_c('v-switch',{staticClass:"d-inline-block",attrs:{"dense":"","true-value":true,"input-value":item.is_active,"loading":_vm.loading_custom_page_id === item.id,"disabled":_vm.loading_custom_page_id === item.id},on:{"change":function (v) { return _vm.update_custom_page({
              page_id: item.id,
              updates: { is_active: v },
            }); }}})]}},{key:"item.expire_at",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_expiration_date_text(item.expire_at))+" ")]}},{key:"item.preview",fn:function(ref){
            var item = ref.item;
return [_c('a',{staticClass:"no-text-decoration",attrs:{"target":"_blank","href":("" + (_vm.selected_brand.url) + (item.nav_slug))}},[_c('v-icon',[_vm._v("mdi-eye-outline")])],1)]}},{key:"item.edit",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{staticClass:"no-text-decoration",attrs:{"to":{
          name: 'admin-brands-id-new-page',
          params: { id: _vm.selected_brand.id },
          query: { id: item.id },
        }}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},{key:"item.delete",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){(_vm.selected_page = item), (_vm.remove_page_dialog = true)}}},[_c('v-icon',{attrs:{"color":"red darken-1"}},[_vm._v("mdi-delete")])],1)]}}],null,true)}),(_vm.remove_page_dialog)?_c('AdminDialog',{attrs:{"show_dialog":_vm.remove_page_dialog,"header":"Are you sure?","loading":_vm.is_deleting,"confirm_text":"Delete","confirm_color":"red"},on:{"update:show_dialog":function($event){_vm.remove_page_dialog=$event},"confirm":function($event){_vm.delete_custom_page({ page_id: _vm.selected_page.id });
      _vm.remove_page_dialog = false;}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" Do you want to delete the selected page? ")]},proxy:true}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }