import moment from "moment";

export function get_HHmm_time_as_seconds(HHmm_time) {
  // HHMM_time comes as "HH:mm" time format
  const hours = Number(HHmm_time.split(":")[0]);
  const minutes = Number(HHmm_time.split(":")[1]);

  return (hours * 60 + minutes) * 60;
}

export function get_seconds_as_HHmm(seconds) {
  // convert seconds to "HH:mm" format
  const total_minutes = String(parseInt(seconds / 60));
  const hours = String(parseInt(total_minutes / 60)).padStart(2, "0");
  const minutes = String(total_minutes % 60).padStart(2, "0");

  return [hours, minutes].join(":");
}

export function format_iso_date_to_uk_date(iso_date) {
  return moment(iso_date, "YYYY-MM-DD").format("DD/MM/YYYY");
}

export function get_ms_until_multiple_of_minutes(minutes) {
  /**
   * This function returns the number of miliseconds remaining until
   * the next multiple of the provided number of minutes.
   * For minutes=20 the multiples would be hh:00, hh:20, hh:40
   * For minutes=15 - hh:00, hh:15, hh:30, hh:45 and so on.
   */
  const now = moment();
  const min_until_multiple = minutes - (parseInt(now.format("m")) % minutes);
  const sec_until_full_minute = 60 - parseInt(now.format("s"));
  const ms_until_full_second = 1000 - parseInt(now.format("SSS"));
  return (
    min_until_multiple * 60000 +
    sec_until_full_minute * 1000 +
    ms_until_full_second
  );
}

export function get_expiration_date_text(date_str) {
  if (!date_str) return "Always Live";
  const expiration_moment = moment.utc(date_str);
  return expiration_moment.isBefore(moment.utc())
    ? "Expired"
    : expiration_moment.format("DD/MM/YYYY HH:mm");
}
