<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="selected_brand.custom_pages"
      :items-per-page="10"
      :loading="custom_page_datatable_loading"
      class="br-16 elevation-1"
    >
      <template v-slot:top>
        <div class="d-flex justify-end align-center br-16 pa-4">
          <v-tooltip
            top
            content-class="custom-tooltip"
            :disabled="selected_brand.custom_pages.length < 5"
          >
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn
                  color="yellow"
                  rounded
                  :to="{
                    name: 'admin-brands-id-new-page',
                  }"
                  class="mr-0"
                  :disabled="selected_brand.custom_pages.length > 4"
                >
                  <v-icon color="black" class="mr-2">mdi-plus</v-icon>Add new
                  page</v-btn
                >
              </div>
            </template>
            <span>The number of custom pages is limited to a maximum of 5</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`header.order`]>
        Order
        <v-tooltip top content-class="custom-tooltip">
          <template v-slot:activator="{ on }">
            <v-icon class="ml-1 mb-1" color="primary" small v-on="on"
              >mdi-information-outline</v-icon
            >
          </template>
          <span>
            Your website's menu bar will follow the order of the pages displayed
            here in this table
          </span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.order`]="{ item }">
        <div
          class="
            change-order-arrow-wrapper
            d-flex
            flex-column
            justify-center
            align-center
            pb-2
          "
        >
          <v-hover v-slot="{ hover }">
            <v-icon
              v-if="item.order > 0"
              class="no-icon-ripple"
              :color="hover ? 'primary' : 'dark-grey'"
              large
              @click="
                change_custom_page_order({
                  page_id: item.id,
                  direction: 'down',
                })
              "
              >mdi-menu-up</v-icon
            >
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-icon
              v-if="item.order < selected_brand.custom_pages.length - 1"
              class="no-icon-ripple"
              :color="hover ? 'primary' : 'dark-grey'"
              large
              @click="
                change_custom_page_order({ page_id: item.id, direction: 'up' })
              "
              >mdi-menu-down</v-icon
            >
          </v-hover>
        </div>
      </template>
      <template v-slot:[`item.is_active`]="{ item }">
        <v-switch
          class="d-inline-block"
          dense
          :true-value="true"
          :input-value="item.is_active"
          :loading="loading_custom_page_id === item.id"
          :disabled="loading_custom_page_id === item.id"
          @change="
            (v) =>
              update_custom_page({
                page_id: item.id,
                updates: { is_active: v },
              })
          "
        ></v-switch>
      </template>
      <template v-slot:[`item.expire_at`]="{ item }">
        {{ get_expiration_date_text(item.expire_at) }}
      </template>
      <template v-slot:[`item.preview`]="{ item }">
        <a
          class="no-text-decoration"
          target="_blank"
          :href="`${selected_brand.url}${item.nav_slug}`"
        >
          <v-icon>mdi-eye-outline</v-icon>
        </a>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <router-link
          class="no-text-decoration"
          :to="{
            name: 'admin-brands-id-new-page',
            params: { id: selected_brand.id },
            query: { id: item.id },
          }"
        >
          <v-icon>mdi-pencil</v-icon>
        </router-link>
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <v-btn
          icon
          @click="(selected_page = item), (remove_page_dialog = true)"
        >
          <v-icon color="red darken-1">mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <AdminDialog
      v-if="remove_page_dialog"
      :show_dialog.sync="remove_page_dialog"
      header="Are you sure?"
      @confirm="
        delete_custom_page({ page_id: selected_page.id });
        remove_page_dialog = false;
      "
      :loading="is_deleting"
      confirm_text="Delete"
      confirm_color="red"
    >
      <template v-slot:[`content`]>
        Do you want to delete the selected page?
      </template>
    </AdminDialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import { get_expiration_date_text } from "@/utils/date_utils";

import AdminDialog from "@/components/base/AdminDialog";

export default {
  name: "CustomPages",
  components: {
    AdminDialog,
  },
  data() {
    return {
      get_expiration_date_text,
      selected_page: null,
      is_deleting: false,
      remove_page_dialog: false,
      headers: [
        { text: "Title", value: "nav_label" },
        { text: "Published", value: "is_active", align: "center" },
        { text: "Expires At", value: "expire_at", width: "10%" },
        {
          text: "Order",
          value: "order",
          align: "center",
          sortable: false,
          width: "10%",
        },
        { text: "Preview", value: "preview", align: "center", sortable: false },
        { text: "Edit", value: "edit", align: "center", sortable: false },
        { text: "Delete", value: "delete", align: "center", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState("SuperAdminStore", [
      "selected_brand",
      "loading_custom_page_id",
      "custom_page_datatable_loading",
    ]),
  },
  methods: {
    ...mapActions("SuperAdminStore", [
      "update_custom_page",
      "delete_custom_page",
      "change_custom_page_order",
    ]),
  },
};
</script>
<style lang="scss" scoped>
::v-deep .change-order-arrow-wrapper > button {
  max-height: 25px;
  &::before,
  &::after {
    max-height: 25px;
  }
}
</style>
